// https://greensock.com/st-demos/

// https://codepen.io/GreenSock/pen/OJyPmgX

gsap.registerPlugin(ScrollTrigger);



const images = gsap.utils.toArray('img');
const loader = document.querySelector('.loader--text');
const updateProgress = (instance) =>
  loader.textContent = `${Math.round(instance.progressedCount * 100 / images.length)}%`;

const showDemo = () => {
  document.body.style.overflow = 'auto';
  document.scrollingElement.scrollTo(0, 0);
  gsap.to(document.querySelector('.loader'), { autoAlpha: 0 });
};

imagesLoaded(images).on('progress', updateProgress).on('always', showDemo);


// Parallax header
// https://codepen.io/GreenSock/pen/OJyPmgX
/*
var $parallax = document.getElementsByClassName('parallax');
if($parallax.length > 0){

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: "#hero",
      start: "top top",
      end: "bottom top",
      scrub: true
    }
  });

  gsap.utils.toArray(".parallax").forEach(layer => {
    const depth = layer.dataset.depth;
    const movement = -(layer.offsetHeight * depth)
    tl.to(layer, {y: movement, ease: "none"}, 0)
  });

}

*/


ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause"
});





// https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.batch()

ScrollTrigger.batch(".aparecer", {
  // interval: 0.1, // time window (in seconds) for batching to occur. 
  // batchMax: 3,   // maximum batch size (targets)
  onEnter: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.1 }),
  // also onLeave, onEnterBack, and onLeaveBack
  // also most normal ScrollTrigger values like start, end, etc.
});
